import type { GetServerSidePropsContext, NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import HeaderSection from "shared/components-pages/agencies-agents-list/Header-section";
import ListSection from "shared/components-pages/agencies-agents-list/List-section";
import en from "lang/en.json";
import es from "lang/es.json";
import { useTranslations } from "next-intl";
import createCanonicalUrl from "@helpers/removeparams";

export async function getServerSideProps({
  locale,
  resolvedUrl,
}: GetServerSidePropsContext) {
  return {
    props: {
      messages: locale === "es" ? es : en,
      url: resolvedUrl,
    },
  };
}
const Listado: NextPage = (props: any) => {
  const [ListType, setListType] = useState<statusListType>(null);
  const [count, setCount] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const Router = useRouter();
  const titleT = useTranslations("title-pages");

  useEffect(() => {
    if (Router.asPath.includes("/listado/agencias")) {
      setSearch("");
      setListType(1);
    } else if (Router.asPath.includes("/listado/agentes")) {
      setSearch("");
      setListType(2);
    }
  }, [Router.asPath]);

  let genericProps = {
    active: ListType,
    handleToggle: setListType,
    setCount,
    count,
    setSearch,
    search,
    url: props.url,
  };
  return (
    <>
      <Head>
        {ListType == 2 && (
          <>
            <title>{titleT("agents")}</title>
            <meta
              name="keywords"
              content="Remax Royal, Agente inmobiliario, Agentes inmobiliarios, Agentes Remax, Remax metropolitana, Remax plus, Agente Remax plus, Agencias inmobiliaria, Agentes Remax República Dominicana"
            />
          </>
        )}
        {ListType == 1 && (
          <>
            <title>{titleT("agencies")}</title>
            <meta
              name="description"
              content="Contacta con nuestras agencias en todo el país y encuentra al agente inmobiliario de tu preferencia para que te guíe en cada paso del camino."
            />

            <meta
              name="keywords"
              content="Remax Royal, Agente inmobiliario, Agentes inmobiliarios, Agentes Remax, Remax metropolitana, Remax plus, Agente Remax plus, Agencias inmobiliaria, Agentes Remax República Dominicana"
            />
          </>
        )}
        <link
          rel="canonical"
          href={createCanonicalUrl(
            `${process.env.NEXT_PUBLIC_DOMAIN_URL}${props.url}`
          )}
          key="canonical"
        />
      </Head>
      <HeaderSection {...genericProps}></HeaderSection>
      <ListSection {...genericProps}></ListSection>
    </>
  );
};

export default Listado;

export type statusListType = 1 | 2 | null;
