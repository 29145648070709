import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { statusListType } from "pages/listado/[type]";
import Search from "../search";
import ListTypeToggle from "../list-type-toggle";
import ResultCounter from "../results-counter";
import Head from "next/head";
import createCanonicalUrl from "@helpers/removeparams";

export interface HeaderSectionInterface {
  active: statusListType;
  handleToggle: Dispatch<SetStateAction<statusListType>>;
  count: number;
  setSearch: Dispatch<SetStateAction<string>>;
  url: string;
}

const HeaderSection: React.FC<HeaderSectionInterface> = (
  props: HeaderSectionInterface
) => {
  const { active, handleToggle, count } = props;

  return (
    <>
     
      <ArticleRoot>
        <section className="search">
          <div className="container">
            <ContainerSearch className="container_title">
              <ListTypeToggle handleToggle={handleToggle} active={active} />
              <Search active={active} setSearch={props.setSearch}></Search>
            </ContainerSearch>
          </div>
        </section>
      </ArticleRoot>
      <ResultCounter active={active} count={count} />
    </>
  );
};

export default HeaderSection;

const ArticleRoot = styled.article`
  & .search {
    padding: 55px 0 30px;
  }
  @media screen and (max-width: 1299px) {
    & .search {
      padding-bottom: 80px;
    }
  }
  @media screen and (max-width: 500px) {
    & .search {
      padding: 25px 0 30px;
    }
  }
`;

const ContainerSearch = styled.div`
  &.container_title {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 14px;
    font-family: var(--font-family);
    color: var(--color-blue-main);

    @media screen and (max-width: 1000px) {
      width: 80%;
    }
    @media screen and (max-width: 1000px) {
      width: 100%;
      padding: 0px 20px;
    }
    @media screen and (max-width: 475px) {
      padding: 0px 10px;
    }
  }
`;
